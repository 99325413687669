<template>
    <b-modal ref="modal" backdrop="static" :keyboard="false" :title="withdrawal ? withdrawal.transaction_id : ''">
        <label for="reason" class="form-label">Enter the reason to block this transaction:</label>
        <textarea
            id="reason"
            class="form-control custom-textarea"
            placeholder="Leave a note here"
            rows="4"
            :class="[{ 'is-invalid': v$.reason.$error, 'is-valid': reason !== '' && !v$.reason.$error }]"
            v-model="reason"
        ></textarea>
        <div v-if="v$.reason.$error" class="invalid-feedback">The field is required.</div>
        <template #footer>
            <b-button variant="light" @click="hide">Cancel</b-button>
            <b-button variant="danger text-white" @click="blockWithdrawal" :loading="loading">Block</b-button>
        </template>
    </b-modal>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
    name: 'MobalBlock',
    props: {
        withdrawal: { type: Object, default: null },
    },
    setup: () => ({ v$: useVuelidate() }),

    data() {
        return {
            loading: false,
            reason: '',
        };
    },

    validations () {
        return {
            reason: { required, $autoDirty: true }
        }
    },

    mounted() {
        this.modal = this.$refs.modal;
    },

    methods: {
        show() {
            this.modal.show();
        },
        hide() {
            this.reason = '';
            this.v$.$reset();
            this.modal.hide();
        },
        async blockWithdrawal() {
            const validated = await this.v$.$validate();
    
            if (!validated) return;
    
            try {
                this.loading = true;
                const { data } = await this.$axios.put(`/_/withdrawals/${this.withdrawal.withdrawal_id}`, {
                    status: 'block',
                    note: {
                        text: this.reason,
                    },
                });
                this.$emit('withdrawalLocked', data)
                this.$toast.success();
                this.hide();
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loading = false;
            }
        },
    },
}
</script>
