<template>
    <div class="withdrawals-list">
        <ui-table-skeleton v-if="loading" />
        <template v-else>
            <div class="row g-2 mb-4">
                <div class="col-md-6 col-lg-3">
                    <ui-input
                        id="search"
                        name="search"
                        label="Search withdrawals"
                        type="text"
                        placeholder="Enter keywords"
                        :debounce="500"
                        v-model:value="filters.q"
                    >
                        <template #prepend>
                            <span class="fas fa-search"></span>
                        </template>
                    </ui-input>
                </div>
                <template v-if="_available_filters">
                    <div class="col-md-6 col-lg-auto">
                        <ui-select-multiple
                            id="status"
                            name="status"
                            label="Status"
                            class="w-100"
                            option-label="key"
                            option-value="value"
                            v-model:value="filters.status"
                            :options="_available_filters.status"
                        />
                    </div>
                    <div class="col-md-6 col-lg-auto">
                        <ui-select-multiple
                            id="gateway"
                            name="gateway"
                            label="Gateway"
                            class="w-100"
                            option-label="key"
                            option-value="value"
                            v-model:value="filters.gateway"
                            :options="_available_filters.gateways"
                        />
                    </div>
                    <div class="col-md-6 col-lg-auto">
                        <ui-select
                            id="in_review"
                            class="w-100"
                            name="in_review"
                            label="In review"
                            option-label="key"
                            option-value="value"
                            chose-option-text="All"
                            v-model:value="filters.in_review"
                            :options="[{ key: 'Yes', value: 'true' }, { key: 'No', value: 'false' }]"
                        />
                    </div>
                    <div class="col-md-6 col-lg-auto">
                        <ui-select-multiple
                            id="currency"
                            name="currency"
                            label="currency"
                            class="w-100"
                            option-label="key"
                            option-value="value"
                            v-model:value="filters.currency"
                            :options="_available_filters.currencies.map(currency => ({ key: currency, value: currency }))"
                        />
                    </div>
                    <div class="col-auto ms-auto">
                        <div v-if="CAN(['write_withdrawals'])">
                            <ui-button
                                v-if="review_list.length" 
                                variant="primary" 
                                data-bs-toggle="modal" 
                                data-bs-target="#modal_review" 
                                :disabled="!review_list.length"
                            > 
                                Mark withdrawals for review ({{ review_list.length }})
                            </ui-button>
                            <ui-button v-else variant="primary" @click="$refs.modal_review_all.show()">
                                Mark all withdrawals for review
                            </ui-button>
                        </div>
                        <b-modal title="Mark orders in review" ref="modal_review_all">
                            Are you sure you want to mark all withdrawals under review?
                            <template #footer>
                                <b-button variant="light" @click="$refs.modal_review_all.hide()">Close</b-button>
                                <b-button @click="reviewWithdrawals" :loading="loadings.mark_all_in_review">Continue</b-button>
                            </template>
                        </b-modal>
                        <div class="modal fade" id="modal_review" tabindex="-1" aria-labelledby="Modal review confirmation" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">Are you sure you want to continue?</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <p>Please make sure you have selected the correct withdrawals in order to review them</p>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                                        <button type="button" class="btn btn-primary" :disabled="loading" @click="reviewWithdrawals">Review</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <ui-table class="withdrawals-list__table mb-4" :items="withdrawals" :fields="fields" :busy="busy" :scopes="['write_withdrawals']">
                <template #cell(checkbox)="data">
                    <input
                        class="form-check-input custom-checkbox"
                        :id="`w_${data.item.transaction_id}`"
                        type="checkbox"
                        :disabled="(data.item.status !== 'processing' || data.item.in_review) || !CAN(['write_withdrawals'])"
                        @change="selectWithdrawal(data.item.withdrawal_id)"
                    />
                </template>
                <template #cell(transaction_id)="data">
                    <ui-link :to="{ name: 'transactions-info', query: { id: data.item.id } }">
                        {{ data.item.transaction_id }}
                    </ui-link>
                    <span v-if="data.item.reference" class="d-block fs-13px word-break">
                        <strong>Concept:</strong> <br />
                        {{ data.item.reference }}
                    </span>
                    <span v-if="data.item.reference_id" class="d-block fs-13px word-break">
                        <strong>Reference Id:</strong> <br />
                        {{ data.item.reference_id }}
                    </span>
                </template>
        
                <template #cell(user_info)="data">
                    <div v-if="!_isEmpty(data.item.account)" class="d-flex flex-column fs-14px">
                        <ui-link weight="medium" :to="{ name: 'accounts-info', query: { id: data.item.account_id } }" target="_blank">
                            <span class="font-weight-600">{{ data.item.account.first_name || '' }} {{ data.item.account.last_name || '' }}</span>
                        </ui-link>
                        <span>
                            <ui-link v-if="data.item.account.email" underline weight="regular" :link="'mailto:' + data.item.account.email">
                                {{ data.item.account.email }}
                            </ui-link>
                            <ui-link v-else-if="data.item.account.phone" underline weight="regular">
                                {{ data.item.account.phone }}
                            </ui-link>
                        </span>
                        <ui-badge v-if="data.item.account.name" variant="success" class="align-self-start badge-large">{{ data.item.account.name }}</ui-badge>
                    </div>
                    <div v-else class="text-muted">Empty</div>
                </template>
        
                <template #cell(payment_method)="data">
                    <ul v-if="data.item.payment_method && Object.keys(data.item.payment_method).length" class="list-unstyled">
                        <li v-show="!['id', 'name'].includes(key)" v-for="(item, key) in data.item.payment_method" :key="key" class="fs-13px mb-1">
                            <strong class="text-capitalize d-inline-block pe-2">{{ key.replace(/_/gi, ' ') }}:</strong>
                            <span>{{ item }}</span>
                        </li>
                    </ul>
                    <span v-else class="fs-14px">Empty</span>
                </template>
        
                <template #cell(gateway)="data">
                    <span class="text-uppercase">{{ data.item.gateway }}</span>
                </template>
        
                <template #cell(amount)="data">
                    <ui-transaction-amount 
                        :amount="data.item.amount" 
                        :currency="data.item.currency" 
                        :type="data.item.type">
                    </ui-transaction-amount>
                </template>
        
                <template #cell(date)="data">
                    {{ $dayjs(data.item.created_at).format('L') }} <br />
                    {{ $dayjs(data.item.created_at).format('LT') }}
                </template>

                <template #cell(status)="data">
                    <ui-status
                        v-if="data.item.in_review && data.item.status !== 'paid' && data.item.status !== 'block'"
                        class="d-inline-flex flex-column align-items-center text-capitalize fs-13px"
                        status="in_review"
                    ></ui-status>
                    <ui-status v-else class="d-inline-flex flex-column align-items-center text-capitalize fs-13px" :status="data.item.status"></ui-status>
                </template>
        
                <template #cell(actions)="data">
                    <b-dropdown variant="none" no-caret toggle-class="border text-muted">
                        <template #button-content>
                            <i class="fas fa-ellipsis-v"></i>
                        </template>
                        <b-dropdown-item
                            v-if="data.item.gateway === 'manual' && data.item.status === 'processing'"
                            :disabled="data.item.status === 'paid'"
                            @click="markAsPaid(data.item.withdrawal_id)"
                            >Mark as paid</b-dropdown-item
                        >
                        <b-dropdown-item
                            v-if="data.item.gateway != 'manual'"
                            :disabled="data.item.status === 'paid' || !data.item.in_review || data.item.status === 'block'"
                            @click="payDispersion(data.item.withdrawal_id)"
                            >Pay withdrawal</b-dropdown-item
                        >
                        <b-dropdown-item
                            :disabled="data.item.status === 'block'"
                            @click="openBlockModal(data.item)"
                            >Set as block</b-dropdown-item
                        >
                    </b-dropdown>
                </template>
        
                <template #cellFull="data">
                    <tr v-if="data.item.error">
                        <td class="col-full" :colspan="fields.length">
                            <p class="text-center text-red p-3 mb-0">
                                <span class="fas fa-info-circle"></span>
                                {{ data.item.error }}
                            </p>
                        </td>
                    </tr>
                </template>
            </ui-table>
            <div class="d-flex justify-content-center">
                <ui-pagination v-model="filters.page" :pages="total_pages" class="mt-3" />
            </div>
            <modal-block ref="modal_block" :withdrawal="selectedWithDrawal" @withdrawalLocked="withdrawalLocked" />
        </template>
    </div>
</template>

<script>
import ModalBlock from '@/components/withdrawals/ModalBlock.vue';
import UiButton from '@/components/ui/buttons/Button';
import UiInput from '@/components/ui/Input';
import UiLink from '@/components/ui/Link.vue';
import UiPagination from '@/components/ui/Pagination';
import UiSelectMultiple from '@/components/ui/inputs/SelectMultiple';
import UiSelect from '@/components/ui/Select';
import UiStatus from '@/components/ui/BadgeStatus';
import UiTable from '@/components/ui/Table.vue';
import UiTableSkeleton from '@/components/ui/skeleton/TableSkeleton.vue';
import UiTransactionAmount from '@/components/ui/TransactionAmount.vue';
import UiBadge from '@/components/ui/Badge';

import table_mixin from '@/mixins/tables.mixin';

export default {
    components: {
        ModalBlock,
        UiButton,
        UiInput,
        UiLink,
        UiPagination,
        UiSelectMultiple,
        UiSelect,
        UiStatus,
        UiTable,
        UiTableSkeleton,
        UiTransactionAmount,
        UiBadge
    },
    mixins: [table_mixin],
    data() {
        return {
            withdrawals: null,
            total_pages: null,
            fields: [
                { key: 'checkbox', label: '' },
                { key: 'transaction_id', label: 'Transaction ID' },
                { key: 'user_info', label: 'Account' },
                { key: 'payment_method', label: 'Payment method' },
                { key: 'gateway', label: 'Gateway' },
                { key: 'amount', label: 'Amount' },
                { key: 'date', label: 'Date' },
                { key: 'status', label: 'Status' },
                { key: 'actions', label: 'Actions' },
            ],
            filters: {
                q: '',
                page: 1,
                limit: 50,
                type: null,
                gateway: null,
                status: null,
                in_review: null,
                currency: null,
            },
            review_list: [],
            loadings: {
                mark_all_in_review: false,
            },
            selectedWithDrawal: null,
            busy: false,
            loading: false,
            inititialLoading: true,
        };
    },
    watch: {
        filters: {
            immediate: false,
            handler() {
                this.getWithdrawals();
            },
            deep: true,
        },
    },

    mounted() {
        this._getFilters('withdrawals');
    },

    methods: {
        async payDispersion(id) {
            try {
                await this.$axios.post('/_/withdrawals/dispersion', {
                    ids: [id],
                });
                this.getWithdrawals();
                this.$toast.success();
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            }
        },
        async markAsPaid(id) {
            try {
                this.loading_mark_as_paid = id;
                await this.$axios.put('/_/withdrawals/paid', {
                    ids: [id],
                });
                this.getWithdrawals();
                this.$toast.success();
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loading_mark_as_paid = null;
            }
        },
        async getWithdrawals() {
            try {
                if (this.inititialLoading) {
                    this.loading = true;
                }

                this.busy = true;
                const params = { ...this.filters };

                this._clearFilters(params);

                const { data } = await this.$axios.get('/_/withdrawals', { params });
                this.total_pages = data.pages;
                this.withdrawals = data.docs;
            } catch (error) {
                console.error(error);
            } finally {
                if (this.inititialLoading) {
                    this.loading = false;
                    this.inititialLoading = false
                }

                this.busy = false;
            }
        },

        async reviewWithdrawals() {
            try {
                this.loadings.mark_all_in_review = true;
                this.busy = true;
                const payload = {};
                if (this.review_list.length) {
                    payload.ids = this.review_list;
                }
                await this.$axios.put('/_/withdrawals/review', payload);
                this.$toast.success('Withdrawals reviewed successfully');
                this.review_list = [];
                this.$refs.modal_review_all.hide();
            } catch (e) {
                this.$toast.error(this.getErrorMessage(e));
            } finally {
                this.busy = false;
                this.loadings.mark_all_in_review = false;
            }
        },

        selectWithdrawal(id) {
            if (!this.review_list.includes(id)) {
                this.review_list.push(id);
            } else {
                this.review_list = this.review_list.filter((r_id) => r_id !== id);
            }
        },

        openBlockModal(withdrawal) {
            this.selectedWithDrawal = withdrawal;
            this.$refs.modal_block.show();
        },

        withdrawalLocked(withdrawal) {
            const searchWithdrawal = this.withdrawals.find((item) => item.id === withdrawal.id);
            searchWithdrawal.status = withdrawal.status;
        }
    },
};
</script>

<style lang="scss" scoped>
.withdrawals-list {
    &__table {
        overflow-x: scroll !important;
    }

    .custom-checkbox[type='checkbox'] {
        width: 1.2rem;
        height: 1.2rem;
        cursor: pointer;
    }
}
</style>
